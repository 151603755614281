<template>
  <div v-if="platformPermissionsLoaded && (checkPermission('loyalty.orders.overview') || checkPermission('loyalty.insights.store'))">              
    <CRow v-if="checkPermission('loyalty.insights.store')" class="insights">
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <span class="dashboard_page_title small flex-grow-1">{{$t('insights.Loyalty_store_orders_insights_snippets')}}</span>          
          <span class="pointer" @click="showFullLoyaltyStoreInsights()">{{$t('insights.Show_full_insights')}}<i class="fa-solid fa-chevron-right ml-1"/></span>
        </div>
      </CCol>      
      <CCol cols="3" xl="3" lg="3" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Points_spent')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="insightsDataLoading" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>           
            <PointsCount v-else
                         mode="with_description"
                         :points="insightsData.metrics[0].user_points_spent"
                         :pointsTitle="$t('By') + ' ' + insightsData.metrics[0].headcount_user_points_spent + ' ' + $t('insights.employees')"
                         :environmentTag="environmentTag">
            </PointsCount>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="3" xl="3" lg="3" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Orders')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="insightsDataLoading" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else
                   mode="with_description"
                   :count="insightsData.metrics[0].orders_amount"
                   :countTitle="$t('For') + ' ' + insightsData.metrics[0].orders_amount_of_products + ' ' + $t('insights.products')"
                   :countIcon="null">
            </Count>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="3" xl="3" lg="3" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Average_points_spent')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="insightsDataLoading" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <PointsCount v-else
                         mode="with_description"
                         :points="(insightsData.metrics[0].user_points_spent / insightsData.headcount.headcount_total).toFixed(0)"
                         :pointsTitle="$t('By') + ' ' + insightsData.headcount.headcount_total + ' ' + $t('insights.employees')"
                         :environmentTag="environmentTag">
            </PointsCount>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="3" xl="3" lg="3" md="12" sm="12">
        <CCard class="mb-0">
          <CCardHeader v-bind:class="{'pb-0': insightsData.categories.length > 0}">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Favorite_categories')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody v-bind:class="{'p-0' : !insightsDataLoading && insightsData.categories.length > 0}">
            <div v-if="insightsDataLoading" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <div v-else>
              <PieChart v-if="insightsData.categories.length > 0" :pieChartSeries="pieChartSeries" pieChartHeight="200px" :companyPrimaryColor="companyPrimaryColor"/>
              <div v-else class="text-center">
                <span>{{$t('insights.No_categories_found')}}</span>
              </div>
            </div>
          </CCardBody>
        </CCard>        
      </CCol> 
    </CRow>    
    <CRow v-if="checkPermission('loyalty.orders.overview')" class="store">
      <CCol cols="12" lg="12">
        <CCard class="mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
                <span>{{ $t('store.Orders') }}</span>
              </CCol>
              <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
                <div v-if="ordersLoaded" class="mt-1 mt-xl-0 d-inline-block align-top">
                  <b-switch class="mb-0 mt-1 mt-xl-0" v-model="orderFilters.hide_delivered" size="is-small" @input="filterOrders()">{{ $t('loyalty.Hide_delivered') }}</b-switch>
                  <CButton v-if="checkPermission('loyalty.orders.export')" class="ml-2 mr-0" color="primary" @click="resetExportFilter(); exportModal = true;">
                    <i class="fas fa-file-arrow-down mr-1"/><span>{{$t('store.Export_orders')}}</span>
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0">
            <div class="order_filters pr-1_5 pl-1_5">
              <CRow class="m-0" :gutters="false">
                <CCol cols="3" lg="3" class="p-0 pr-1_5 pl-1_5 search">
                  <label><b>{{$t('store.Search_for_order')}}</b></label>
                  <CInput type="text" class="mb-0 searchbar" v-model="orderFilters.order_number" v-debounce:1s="filterOrders"></CInput>
                </CCol>
                <CCol cols="3" lg="3" class="p-0 pr-1_5 pl-1_5 search">
                  <label><b>{{$t('store.Search_for_product')}}</b></label>
                  <CInput type="text" class="mb-0 searchbar" v-model="orderFilters.product_name" v-debounce:1s="filterOrders"></CInput>
                </CCol>
                <CCol cols="3" lg="3" class="p-0 pr-1_5 pl-1_5 search">
                  <label><b>{{$t('store.Search_for_category')}}</b></label>
                  <CInput type="text" class="mb-0 searchbar" v-model="orderFilters.category_name" v-debounce:1s="filterOrders"></CInput>
                </CCol>
                <CCol cols="3" lg="3" class="p-0 pr-1_5 pl-1_5 search">
                  <label><b>{{$t('store.Search_for_employee')}}</b></label>
                  <CInput type="text" class="mb-0 searchbar" v-model="orderFilters.employee_name" v-debounce:1s="filterOrders"></CInput>
                </CCol>                                                
              </CRow>
            </div>
            <hr class="mt-2_5" v-bind:class="{'mb-2_5' : orders.length > 0, 'mb-0' : orders.length == 0}">
            <div class="data_table_container">
              <CRow v-if="ordersLoading === true">
                <CCol cols="12" lg="12">
                  <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                </CCol>
              </CRow>
              <CRow v-else class="orders">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <div v-if="orders.length > 0">
                    <b-table class="data_table"
                             ref="orderTable"
                             :data="orders"
                             :striped="true"
                             :hoverable="true"
                             :mobile-cards="true"
                             :current-page.sync="currentPage"
                             backend-pagination
                             :paginated="isPaginated"
                             :total="totalItems"
                             :per-page="perPage"
                             :pagination-simple="isPaginationSimple"
                             :pagination-position="paginationPosition"
                             @page-change="onPageChange"
                             backend-sorting
                             :default-sort="[sortField, sortOrder]"
                             :default-sort-direction="defaultSortDirection"
                             :sort-icon="sortIcon"
                             :sort-icon-size="sortIconSize"
                             @sort="onTableSort">

                      <template slot-scope="props">
                        <b-table-column field="order_number" :label="$t('store.Order_number')" :searchable="false" :sortable="true" width="7.5%">
                          <span>{{props.row.order_number}}</span>
                        </b-table-column>
                        <b-table-column field="product_name" :label="$t('store.Product_ordered')" :searchable="false" :sortable="true" width="15%">
                          <div class="d-flex align-items-center">
                            <div v-if="props.row.product_image_id && props.row.from_marketplace === 'N'"
                                class="store_product_image d-inline-block align-middle mr-2"
                                v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                            </div>
                            <div v-else-if="props.row.product_image_id && props.row.from_marketplace === 'Y'"
                                class="store_product_image d-inline-block align-middle mr-2"
                                v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product-mp/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
                            </div>                    
                            <div v-else-if="props.row.loyalty_store_partner_product_external_id"
                                class="item_image d-inline-block align-middle mr-2"
                                v-bind:style="{ backgroundImage: 'url(' + props.row.loyalty_store_partner_product_image_url + ')' }">
                            </div>
                            <div v-else class="store_product_icon d-inline-block align-middle mr-2"> 
                              <i class="fas fa-box"/>
                            </div>
                            <div>
                              <span>{{props.row.product_name}}</span>
                              <span class="d-block mt-1 meta" v-html="props.row.variation_name"></span>
                            </div>                        
                          </div>
                        </b-table-column>
                        <b-table-column field="loyalty_store_category_name" :label="$t('common.Category')" :searchable="false" :sortable="true" width="15%">
                          <span>{{props.row.loyalty_store_category_name}}</span>
                        </b-table-column>                  
                        <b-table-column field="ordered_by" :label="$t('store.Ordered_by')" :searchable="false" :sortable="true" width="15%">
                          <div class="d-flex align-items-center">
                            <userProfilePopover ref="userProfilePopover"
                                                mode="user" 
                                                :userName="props.row.ordered_by"
                                                :userProfileImage="props.row.ordered_by_profile_image" 
                                                :userIdExternal="props.row.ordered_by_user_id_external"
                                                :senderIdExternal="null"
                                                popoverPlacement="right"
                                                class="mr-2">
                            </userProfilePopover>
                            <div>
                              <span>{{props.row.ordered_by}}</span>
                              <span class="d-block mt-1 meta">{{props.row.team_name}} - {{props.row.department_name}}</span>
                            </div>                        
                          </div>
                        </b-table-column>
                        <b-table-column field="points_spent" :label="$t('store.Points_spent')" :sortable="true" width="10%">
                          <div class="d-flex align-items-center credits">
                            <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                            <span>{{props.row.points_spent}} {{props.row.points_spent !== 1 ? $t('common.points') : $t('common.point')}}</span>
                          </div>
                        </b-table-column>
                        <b-table-column field="ordered" :label="$t('store.Ordered_at')" :sortable="true" width="10%">
                          <span>{{props.row.ordered | moment("DD-MM-YYYY HH:mm")}}</span>
                        </b-table-column>
                        <b-table-column field="delivered" :label="$t('loyalty.Deliver_status')" class="deliver_status" :sortable="true" width="12.5%">
                          <div class="d-flex">
                            <div class="d-flex align-items-center">
                              <i class="fas mr-2" v-bind:class="{'fa-check' : props.row.delivered, 'fa-times' : !props.row.delivered}"></i>
                            </div>
                            <div v-if="props.row.delivered" class="d-flex flex-column">
                              <span><em>@ {{props.row.delivered | moment("DD-MM-YYYY HH:mm")}}</em></span>
                              <span class="d-block mt-1 meta"><em>{{props.row.delivery_confirmed_by}}</em></span>
                            </div>
                            <div v-else class="d-flex flex-column align-items-center">
                              <CButton v-if="!props.row.delivered" class="m-0" color="primary" @click="confirmModal = true; confirmModalData = props.row;">
                                <i class="fa-solid fa-circle-check mr-1"></i><span>{{$t('store.Mark_as_delivered')}}</span>
                              </CButton>
                            </div>                      
                          </div>                                        
                        </b-table-column>
                      </template>
                    </b-table>
                  </div>
                  <div v-else class="p-3 text-center">
                    <span v-if="orderFilters.order_number || orderFilters.product_name || orderFilters.category_name || orderFilters.employee_name">{{$t('store.No_orders_based_on_filters')}}</span>
                    <span v-else>{{$t('store.No_orders_found')}}</span>
                  </div>                  
                </CCol>
              </CRow>
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <b-modal v-if="checkPermission('loyalty.orders.markdelivered')" :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('store.Confirm_delivery')}} {{confirmModalData.title}}
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0">
                <p class="m-0">{{$t('store.Sure_to_mark_as_delivered')}}</p>
              </CCol>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <p class="mt-0 mb-1"><b>{{$t('store.Product_ordered')}}:</b> {{confirmModalData.product_name}}</p>
                <p class="mt-0 mb-1"><b>{{$t('store.Ordered_by')}}:</b> {{confirmModalData.ordered_by}} ({{confirmModalData.team_name}} - {{confirmModalData.department_name}})</p>
                <p class="m-0"><b>{{$t('store.Ordered_at')}}:</b> {{confirmModalData.ordered | moment("DD-MM-YYYY HH:mm")}}</p>
              </CCol>            
            </CRow>    
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="confirmDelivery(confirmModalData.loyalty_store_order_id_external)"><i class="fas fa-check mr-1"/>{{ $t('store.Mark_as_delivered') }}</CButton>
            <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
          </CCardFooter>          
        </CCard>
      </b-modal>

      <b-modal :can-cancel="['x']" :active.sync="exportModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-2">
            <span>{{$t('store.Export_orders')}}</span>
          </CCardHeader>
          <CCardBody class="pt-0">
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-2">
                <span>{{$t('common.Select_export_format')}}</span>
              </CCol>           
            </CRow>
            <CRow :gutters="false">
              <CCol cols="6" lg="6" class="pt-0 pr-1_5">
                <CCard class="m-0 pointer export" :class="{'selected' : exportFilter.format === 'csv'}" @click="setExportFilterFormat('csv')">
                  <CCardBody>
                    <span><i class="fas fa-file-arrow-down mr-1"/>CSV</span>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0 pl-1_5">
                <CCard class="m-0 pointer export" :class="{'selected' : exportFilter.format === 'json'}" @click="setExportFilterFormat('json')">
                  <CCardBody>
                    <span><i class="fas fa-file-arrow-down mr-1"/>JSON</span>
                  </CCardBody>
                </CCard>
              </CCol>                       
            </CRow>            
            <CRow>
              <CCol cols="12" lg="12" class="pb-2">
                <span>{{$t('common.Define_time_range_for_export')}}</span>
              </CCol>           
            </CRow>
            <CRow class="insights_filters" :gutters="false">
              <CCol class="pr-1_5">
                <label class="mb-1">{{$t('common.Date_from')}}</label>
                <b-datepicker v-model="exportFilter.date_from"
                              inline
                              icon="calendar-day"
                              icon-pack="fas"
                              :first-day-of-week="1"
                              :show-week-number="true"
                              :max-date="new Date()"
                              :years-range="[-3, 10]"
                              :placeholder="$t('common.click_to_select')"
                              class="d-inline-block align-middle">
                  <CButton color="primary" @click="exportFilter.date_from = new Date();">
                    <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
                  </CButton>
                </b-datepicker>
              </CCol>
              <CCol class="pl-2">
                <label class="mb-1">{{$t('common.Date_to')}}</label>
                <b-datepicker v-model="exportFilter.date_to"
                              inline
                              icon="calendar-day"
                              icon-pack="fas"
                              :first-day-of-week="1"
                              :show-week-number="true"
                              :max-date="new Date()"
                              :years-range="[-3, 10]"
                              :placeholder="$t('common.click_to_select')"
                              class="d-inline-block align-middle">
                  <CButton color="primary" @click="exportFilter.date_to = new Date();">
                    <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
                  </CButton>
                </b-datepicker>      
              </CCol>
            </CRow>              
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="exportOrders()" :disabled="!exportFilter.format">
              <span><i class="fas fa-file-arrow-down mr-1"/>{{$t('store.Export_orders')}}</span>
            </CButton>
            <CButton color="secondary" @click="exportModal = false">
              <span><i class="fas fa-times mr-1"/>{{$t('cancel')}}</span>
            </CButton>
          </CCardFooter>          
        </CCard>
      </b-modal>      
    </CRow>
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';

import Count from '@/components/insights/common/Count.vue';
import PieChart from '@/components/insights/common/PieChart.vue';
import PointsCount from '@/components/insights/loyalty/PointsCount.vue';

export default {
  name: 'Orders',
  components: {
    Multiselect,
    loadingSpinner,
    noPermission,
    userProfilePopover,
    Count,
    PieChart,
    PointsCount
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      orders: [],
      ordersLoading: false,
      ordersLoaded: false,
      orderFilters: {
        order_number: '',
        product_name: '',
        category_name: '',
        employee_name: '',
        hide_delivered: false    
      },
      sortField: 'order_number',
      sortOrder: 'desc',
      defaultSortDirection: 'desc',  
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: false,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      totalItems: 0,
      ordersStartIndex: 0,
      confirmModal: false,
      confirmModalData: {},
      exportModal: false,
      exportFilter: {
        format: null,
        date_from: new Date(),
        date_to: new Date()
      },
      insightsDataLoading: false,                  
      insightsData: {
        metrics: [{
          user_points_spent: 0,
          headcount_user_points_spent: 0,
          orders_amount: 0,
          orders_amount_of_products: 0
        }],
        headcount: { headcount_total: 0 },
        categories: []
      }
    }
  },
  methods: {
    getOrders() { 
      // Start the loader
      if(this.ordersLoaded === false) this.ordersLoading = true;
      // Set the params
      let params = {};
      params.order_limit = this.perPage;
      params.order_filters = this.orderFilters;
      params.order_filters.sort_field = this.sortField;
      params.order_filters.sort_order = this.sortOrder;

      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/orders/' + this.ordersStartIndex, params)
      .then(res => {      
        // Clear the orders array
        this.orders = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_loyalty_store_orders;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the loyalty_store_orders to the orders array
        this.orders = res.data.data.loyalty_store_orders;
        // Enable the pagination if necessary
        (this.totalItems > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.ordersLoading = false;
        // Update the ordersLoaded value
        this.ordersLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    onPageChange(page) {
      // Set the ordersStartIndex value
      (page > 1) ? this.ordersStartIndex = (page - 1) * this.perPage : this.ordersStartIndex = 0;
      // Get the orders
      this.getOrders();
    },
    onTableSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // Reset the pagination;
      this.resetPagination();
      // Get the orders
      this.getOrders();
    },    
    filterOrders() {
      // Reset the pagination;
      this.resetPagination();
      // Get the orders
      this.getOrders();
    },
    resetPagination() {
      this.currentPage = 1;
      this.ordersStartIndex = 0;
    },
    getInsightsData() {
      // Start the loader
      this.insightsDataLoading = true;
      // Get the topics insights data
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/insights/store')      
      .then(res => {
        // Set the insightsData
        this.insightsData = res.data.data;
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');
        // Update the lineChartSeries
        this.pieChartSeries = [{ name: this.$t('common.Category'), data: this.insightsData.categories }];        
        // Wait 2 seconds close the loader
        setTimeout(function(){
          this.insightsDataLoading = false;          
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });                  
    },    
    confirmDelivery(orderIdExternal) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/order/' + orderIdExternal + '/delivered')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('store.Marked_as_delivered'), type: 'is-success', duration: 2000 });
        // Update the orders
        this.getOrders();
        // Close the modal
        this.confirmModal = false;
      })
      .catch(err => {
        // console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    exportOrders() {
      // Set the params
      let params = {};      
      params.order_filters = this.orderFilters;      
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.exportFilter.date_from.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.exportFilter.date_to.toISOString(), "yyyy-MM-dd");
      // Set outputFormat variable
      let outputFormat = this.exportFilter.format;
      // Export the orders
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/orders/' + dateFromAPI + '/' + dateToAPI + '/export/' + outputFormat, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });
        // Close the modal
        this.exportModal = false;
        // Reset the export filter
        this.resetExportFilter();

        if(!window.navigator.msSaveOrOpenBlob){
          // Blob navigator
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;
            url = window.URL.createObjectURL(new Blob([outputData]));  
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
            url = window.URL.createObjectURL(new Blob([outputData]));
          }
          // Create a new 'a' element
          const link = document.createElement('a');
          // Set the generated URL as href of the new element
          link.href = url;
          // Set the download attribute based on the output format
          if(outputFormat === 'csv') {
            link.setAttribute('download', 'loyalty-store-orders.csv');
          } else if(outputFormat === 'json') {
            link.setAttribute('download', 'loyalty-store-orders.json');
          }
          // Append the link to the body
          document.body.appendChild(link);
          // Click the link
          link.click();
        } else {
          // Blob for Explorer 11
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;
            url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]),"loyalty-store-orders.csv");
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
            url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]),"loyalty-store-orders.json");
          }
        }
      })
      .catch(err => {
        console.error(err); 
      }); 
    },    
    setExportFilterFormat(format) {
      this.exportFilter.format = format;
      this.$forceUpdate();
    },
    resetExportFilter() {
      this.exportFilter = {
        format: null,
        date_from: new Date(),
        date_to: new Date()
      }
      // Set the default for date_from to today minus 30 days
      this.exportFilter.date_from.setDate(this.exportFilter.date_from.getDate() - 30);
    },
    showFullLoyaltyStoreInsights() {
      // Navigate to loyalty store insights page      
      if(this.$router.currentRoute.path !== '/insights/loyalty/store') this.$router.push({path: '/insights/loyalty/store'});
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Get the store insights data is allowed
        if(this.platformPermissions.includes('loyalty.insights.store')) this.getInsightsData();
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }                
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.getPlatformPermissions();
    this.getOrders();
    this.resetExportFilter();
  }
}
</script>